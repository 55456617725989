<template>
  <v-card>
    <v-chip-group
        column
        multiple
        active-class="primary darken-3"
        @change="change($event)"
        :value="value"
    >
      <v-chip
          small
          label
          :value="area"
          v-for="area in areas"
          :key="area.area"
          :ripple="false"
          class="pa-2"
      >
        {{ area.area }}
      </v-chip>
    </v-chip-group>
  </v-card>
</template>

<script>
import areas from "@/helper/areas";

export default {
    name: "SpoiledAreaFilter",
    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        areas() {
            return areas;
        },
    },
    methods: {
        change(value) {
            this.$emit('input', value);
        },
    },
    watch: {
        '$store.state.Log.log.id'() {
            this.change([]);
        },
    },
}
</script>

<style scoped>
/deep/ .v-slide-group__content {
    justify-content: center;
}
</style>