<template>
    <v-layout d-flex column fill-height>
        <spoiled-banner
            class="mb-5"
        >
          <spoiled-area-filter v-model="areas" @input="updatePage" class="mb-3"/>
          <spoiled-search v-model="search" @input="updatePage"/>
        </spoiled-banner>

        <v-flex grow class="mb-3">
            <v-data-iterator
                :items="locations"
                item-key="name"
                :items-per-page="itemsPerPage"
                :page="page"
                hide-default-footer
            >
                <template v-slot:default="{ items }">
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="2" v-for="location in items" :key="location.name">
                            <spoiled-location :value="location"/>
                        </v-col>
                    </v-row>
                </template>
            </v-data-iterator>
        </v-flex>

        <v-pagination
            :length="pages"
            :total-visible="15"
            v-model="page"
            class="nocopy"
        />
    </v-layout>
</template>

<script>
import SpoiledLocation from "@/components/base/SpoiledLocation";
import SpoiledAreaFilter from "@/components/base/SpoiledAreaFilter";
import SpoiledSearch from "@/components/base/SpoiledSearch.vue";
import SpoiledBanner from "@/components/base/SpoiledBanner.vue";

export default {
    name: "SpoiledLocations",
    components: {SpoiledBanner, SpoiledSearch, SpoiledAreaFilter, SpoiledLocation},
    data: function () {
        return {
            search: null,
            page: 1,
            itemsPerPage: 30,
            areas: [],
        };
    },
    computed: {
        locations() {
          return this.$options.filters.filterBy({
            items: this.$store.getters['Log/locations'],
            areas: this.areas,
            search: this.search,
          });
        },
        pages() {
            return Math.ceil(this.locations.length / this.itemsPerPage) || 1;
        },
    },
    methods: {
        updatePage() {
            this.page = this.page > this.pages ? this.pages : this.page;
        },
    },
    watch: {
        '$store.state.Log.log.id'() {
            this.page = 1;
        },
    },
}
</script>

<style scoped>

</style>