export default {
    name: 'Spoiled',
    version: '1.0.0',
    changelog: [
        {version: '1.0.0', changes: ['Added randomizer icon display', 'Added more tooltips', 'Sorted locations/playthrough/hints by name', 'Improved search functionality', 'Improved internals']},
        {version: '0.9.1', changes: ['Added playthrough sphere display to navigation']},
        {version: '0.9.0', changes: ['Added enabled tricks', 'Added triforce hunt progress', 'Added more hints', 'Sorted hints by name']},
        {version: '0.8.1', changes: ['Improved UI', 'Improved internals', 'Improved usability']},
        {version: '0.8.0', changes: ['Added Log sharing']},
        {version: '0.7.0', changes: ['Added drag and drop for log file', 'Improved playthrough view', 'Improved settings view', 'Improved usability', 'Structural improvements', 'Minor bugfixes']},
        {version: '0.6.1', changes: ['Improved map visuals', 'Improved route visuals', 'Improved map load']},
        {version: '0.6.0', changes: ['Reworked entrances and navigation']},
        {version: '0.5.1', changes: ['Improved internals', 'Improved connections view', 'Improved invalid upload feedback', 'Minor bugfixes']},
        {version: '0.5.0', changes: ['Added entrances', 'Added entrance navigation', 'Added song hints']},
        {version: '0.4.1', changes: ['Added more area filters', 'Added checkable hints', 'Improved internals']},
        {version: '0.4.0', changes: ['Added area filter', 'Added "Found" setting', 'Reworked internal structure', 'Improved hints', 'Improved general UI']},
        {version: '0.3.1', changes: ['Added log download', 'Added light theme', 'Added history delete', 'Removed Playthrough for "No logic" randomizer' ,'General improvements']},
        {version: '0.3.0', changes: ['UI rework']},
        {version: '0.2.1', changes: ['Added non-randomized prices', 'Improved UI', 'Improved history']},
        {version: '0.2.0', changes: ['Added shop prices', 'Added log history', 'Added altar hints', 'Improved internal structure', 'Improved changelog', 'Improved general UI', 'Improved hints list']},
        {version: '0.1.1', changes: ['Added changelog', 'Added seed display', 'Improved log parser', 'Improved location check usability', 'Improved overall visuals']},
        {version: '0.1.0', changes: ['Initial version']},
    ],
}