<script>
export default {
  name: "SpoiledBanner"
}
</script>

<template>
  <v-banner
      app
      sticky
      class="banner"
  >
    <slot/>
  </v-banner>
</template>

<style scoped>
.banner {
  z-index: 3 !important;
  background: #080808 !important;
}

/deep/ .v-slide-group__content {
  justify-content: center;
}

/deep/ .v-banner__wrapper {
  padding: 0 !important;
  border-bottom: 0 !important;
}

/deep/ .v-banner__content {
  padding: 0 !important;
}
</style>