<template>
    <div
        class="settings"
    >
        <spoiled-toggle-button
            title="Found"
            tooltip="Veil/unveil found locations"
            v-model="showFound"
            class="mx-1"
        />
        <spoiled-toggle-button
            title="Spoilers"
            tooltip="Veil/unveil all locations"
            v-model="showSpoilers"
            class="mx-1"
        />
        <spoiled-toggle-button
            title="Traps"
            tooltip="Veil/unveil traps"
            v-model="showTraps"
            class="mx-1"
        />
        <spoiled-toggle-button
            title="Prices"
            tooltip="Veil/unveil prices"
            v-model="showPrices"
            class="mx-1"
        />
        <spoiled-toggle-button
            title="Search"
            tooltip="Search in veiled locations"
            v-model="showSearch"
            class="mx-1"
        />
    </div>
</template>

<script>
import SpoiledToggleButton from "@/components/settings/SpoiledToggleButton";

export default {
    name: "SpoiledShowSettings",
    components: {SpoiledToggleButton},
    computed: {
        showFound: {
            get() {
                return this.$store.getters['Settings/showFound'];
            },
            set(value) {
                this.$store.commit('Settings/setFound', value);
            },
        },
        showSpoilers: {
            get() {
                return this.$store.getters['Settings/showSpoilers'];
            },
            set(value) {
                this.$store.commit('Settings/setSpoilers', value);
            },
        },
        showTraps: {
            get() {
                return this.$store.getters['Settings/showTraps'];
            },
            set(value) {
                this.$store.commit('Settings/setTraps', value);
            },
        },
        showPrices: {
            get() {
                return this.$store.getters['Settings/showPrices'];
            },
            set(value) {
                this.$store.commit('Settings/setPrices', value);
            },
        },
        showSearch: {
            get() {
                return this.$store.getters['Settings/showSearch'];
            },
            set(value) {
                this.$store.commit('Settings/setSearch', value);
            },
        },
    },
}
</script>

<style scoped>
.settings {
  position: relative;
}
</style>