<template>
  <v-tooltip
      top
      :open-delay="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
          text
          @click="click"
          :color="color"
          width="125"
          v-on="on"
      >
        <v-icon left>
          {{ icon }}
        </v-icon>

        {{ title }}
      </v-btn>
    </template>

    {{ tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  name: "SpoiledToggleButton",
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    color() {
      return this.value ? 'red' : 'green';
    },
    icon() {
      return this.value ? 'mdi-eye-outline' : 'mdi-eye-off-outline';
    },
  },
  methods: {
    click() {
      this.$emit('input', !this.value);
    },
  },
}
</script>

<style scoped>

</style>